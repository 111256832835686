import axios from 'axios';
import globle from '../globle'
// 导入路径配置

const api = axios.create({
    baseURL: globle.allurl, // 基础 URL
    timeout: 50000, // 超时时间
});
axios.interceptors.request.use(config => {

    let user;
    user = JSON.parse(localStorage.getItem("user"));
    // 为请求头对象，添加token验证的Authorization字段
    if (user == null) {
        config.headers.Authorization = null
    } else {
        config.headers.Authorization = user.token;
        config.headers.user = user;
    }
    return config;
});

// 设置响应拦截器
axios.interceptors.response.use(
    // 200之内会触发这个
    response => {
        console.log(response.data.code)
        if(response.data.code!==undefined&&response.data.code!=null&&response.data.code!=="200") {
            console.log("收到相应失败")
            console.log(response.data)
        }
        // 如果后端返回成功响应，则直接返回响应数据
        return response;
    },
    // 超出200会触发这个
    error => {
        console.log("收到响应失败" + error.assign)
        // router.push('/login');
    }
);

api.interceptors.response.use(
    response => response.data,
    error => {
        console.error('API Error:', error);
        return Promise.reject(error);
    }
);

export default api;
