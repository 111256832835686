import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Rem from '../Rme.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import globle from "@/globle";
import axios from './axios/api'


import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';

import '@kangc/v-md-editor/lib/theme/style/github.css';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';



import PortalVue from 'portal-vue'


VMdEditor.use(githubTheme, {
  Hljs: hljs,
});
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
Vue.use(PortalVue)
Vue.use(ElementUI)
Vue.use(VMdEditor);
Vue.use(VMdPreview);
//不能用vue.use引入，会导致请求拦截错误，无限跳转首页
Vue.prototype.$axios=axios
Vue.prototype.$globle = globle
Vue.config.productionTip = false
// Vue.prototype.steam = '/steam';
// Vue.prototype.psname = '/psname';
new Vue({
  Rem,
  router,
  render: h => h(App),
}).$mount('#app')
