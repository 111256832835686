import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";
import globle from "@/globle";


// const originalPush = VueRouter.prototype.push
//
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: ()=>import("../pages/MainPage.vue"), // 布局组件，包含固定标题部分
        children: [
            {
                path: 'ConsultantDetail',
                name: 'ConsultantDetail',

                component: () => import("../pages/Consultant/ConsultantDetail.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path: 'fufei',
                name: 'fufei',
                component: () => import("../pages/Fufei/index.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path: 'gameDetail',
                name: 'gameDetail',
                component: () => import("../pages/game/gameDetail.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path: 'newgameDetail',
                name: 'newgameDetail',
                component: () => import("../pages/game/newgameDetail.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path: 'psgameDetail',
                name: 'psgameDetail',
                component: () => import("../pages/game/psgamedetial.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path: 'consultant',
                name: 'consultant',
                component: () => import("../pages/Consultant/index.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            // {
            //   path: '/',
            //   name: 'home',
            //   component: Home,
            //   meta: {
            //     keepAlive: false // 不需要缓存
            //   }
            // },
            {
                path: '/',
                name: 'home',
                component: () => import("../pages/Home/index.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path: 'chatroom',
                name: 'chatroom',
                component: () => import("../pages/ChatRoom/index.vue"),
                meta: {
                    keepAlive: false,
                    requiresAuth: true // 需要登录才能访问聊天室
                }
            },
            {
                path: 'information',
                name: 'information',
                component: () => import("../pages/article/articledetiail.vue"),
                meta: {
                    keepAlive: false,
                    requiresAuth: true
                    // 不需要缓存
                }
            },
            {
                path: 'informations',
                name: 'informations',
                component: () => import("../pages/article/index.vue"),
                meta: {
                    keepAlive: false,
                    // 不需要缓存
                }
            },
            {
                path: 'game',
                name: 'game',
                component: () => import("../pages/game/index.vue"),
                meta: {
                    keepAlive: true, //该字段表示该页面需要缓存
                },
                children: [
                    {
                        path: 'psgame',
                        name: 'psgame',
                        component: () => import("../pages/game/psgame.vue"),
                        meta: {
                            keepAlive: true, //该字段表示该页面需要缓存
                        },
                    },
                    {
                        path: 'pcgame',
                        name: 'pcgame',
                        component: () => import("../pages/game/pcgame.vue"),
                        meta: {
                            keepAlive: true, //该字段表示该页面需要缓存
                        },
                    },
                ]
            },
            {
                path: 'details',
                name: 'details',
                component: () => import("../pages/Details/index.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path: 'userdetail',
                name: 'userdetail',
                component: () => import("../pages/userdetail/userdetail.vue"),
                meta: {
                    keepAlive: false,
                    requiresAuth: true// 不需要缓存
                }
            },
            {
                path: 'charts',
                name: 'charts',
                component: () => import("../pages/Charts/index.vue"),
                meta: {
                    keepAlive: false // 不需要缓存
                }
            },
            {
                path:'/steamData',
                name: 'steamData',
                component: () => import("../pages/Charts/SteamData.vue"),
            }
        ]
    },
    {
        path: '/register',
        name: 'register',
        component: () => import("../pages/register/register.vue"),
        meta: {
            keepAlive: false,
            guestOnly: true// 不需要缓存
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("../pages/Login/index.vue"),
        meta: {
            keepAlive: false,
            guestOnly: true// 不需要缓存
        }
    },
    {
        path: '/createarticle',
        name: 'createarticle',
        component: () => import("../pages/article/createarticle.vue"),
        meta: {
            requiresAuth: true
        }
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
// router.beforeEach((to, from, next) => {
//   let user="";
//   user=JSON.parse(sessionStorage.getItem("user"));
//   if (to.name !== 'login' &&user===null) next({ name: 'login' })
//   else next()
// })
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth) {
        axios.get(globle.allurl + "checktoken").then(res => {
            if (res.data) {
                next()
            } else {
                alert("请登录")
                next({name: 'login', query: {redirect: to.fullPath}});
            }
        })
    }else if(to.meta.guestOnly){
        axios.get(globle.allurl + "checktoken").then(res => {
            if (res.data) {
                next({name: 'home'});
            } else {
                next();
            }
        })
    } else {
        next()
    }

})
export default router
